import TagGroup from '../TagGroup';
import isObject from '../../../../common/guards/isObject';
import isTags from '../../guards/isTags';

const isTagGroup = (tagGroup: unknown): tagGroup is TagGroup =>
  isObject(tagGroup) &&
  (tagGroup as TagGroup).name !== undefined &&
  isTags((tagGroup as TagGroup).tags);

export default isTagGroup;
