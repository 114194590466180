import { useLocation as useLocationHook } from 'react-router-dom';

import UrlPath from '../../tools/web/url/paths/UrlPath';

interface UseLocation {
  path: UrlPath;
}

const useLocation = (): UseLocation => {
  const location = useLocationHook();

  return {
    path: location.pathname,
  };
};

export default useLocation;
