import ConstructedCvActivitySubactivity from '../ConstructedCvActivitySubactivity';
import TagsResource from '../../../../../../../../../tools/resources/types/business/tags/TagsResource';
import ConstructedCvSubactivitySkill from '../../fields/skills/constructed/ConstructedCvSubactivitySkill';
import updateConstructedSubactivitySkillWithTagsResource from '../../fields/skills/constructed/operations/updateConstructedSubactivitySkillWithTagsResource';

const updateConstructedActivitySubactivityWithTagsResource = (
  constructedSubactivity: ConstructedCvActivitySubactivity,
  tagsResource: TagsResource,
): ConstructedCvActivitySubactivity => {
  if (constructedSubactivity.skills?.length === 0) {
    return constructedSubactivity;
  }

  const skills: ConstructedCvSubactivitySkill[] = [];

  constructedSubactivity.skills?.forEach((constructedSkill) => {
    skills.push(
      updateConstructedSubactivitySkillWithTagsResource(
        constructedSkill as ConstructedCvSubactivitySkill,
        tagsResource,
      ),
    );
  });

  if (skills.length > 0) {
    constructedSubactivity.skills = skills;
  } else {
    constructedSubactivity.skills = undefined;
  }

  return constructedSubactivity;
};

export default updateConstructedActivitySubactivityWithTagsResource;
