import React from 'react';

import { AccordionDetails, Box, Stack } from '@mui/material';

import useAssetsResources from '../../../../../../../hooks/resources/useAssetsResources';
import useCurrentBreakpoint from '../../../../../../../hooks/theme/useCurrentBreakpoint';
import useExternalNavigation from '../../../../../../../hooks/navigation/useExternalNavigation';

import CvActivity from '../../../../../../../types/business/cv/fields/activity/CvActivity';
import UrlNavEndpoint from '../../../../../../../tools/navigation/endpoints/UrlNavEndpoint';
import {
  COMMON_SOCIAL_LINK_TYPE,
  GITHUB_SOCIAL_LINK_TYPE,
  GITLAB_SOCIAL_LINK_TYPE,
} from '../../../../../../../types/business/social/link/SocialLinkType';

import { ComponentProps } from '../../../../../ComponentProps';
import TruncatableTextMolecule from '../../../../text/truncatable/TruncatableTextMolecule';
import sortSubactivities from '../../../../../../../tools/business/cv/sort/sortSubactivities';
import SkillListMolecule from '../../../../lists/skill/SkillListMolecule';
import sortSkillsExperience from '../../../../../../../tools/business/cv/sort/sortSkillsExperience';
import calculateSkillsExperience from '../../../../../../../tools/business/cv/calculate/calculateSkillsExperience';
import NavListMolecule, {
  NavListMoleculeItem,
} from '../../../../lists/nav/NavListMolecule';

type DetailsOfActivityAccordionCardMoleculeProps = ComponentProps & {
  activity: CvActivity;
};

const DetailsOfActivityAccordionCardMolecule: React.FC<
  DetailsOfActivityAccordionCardMoleculeProps
> = ({ className, activity }) => {
  const breakpoint = useCurrentBreakpoint();
  const { gitLabLogo, gitHubLogo } = useAssetsResources();
  const { toGitLab, toGitHub } = useExternalNavigation();

  const renderDescription = () => {
    if (activity.subactivities) {
      const WHITESPACE = ' ';
      const isAnyDescriptionExists = activity.subactivities.some(
        (subactivity) => subactivity.description,
      );
      if (isAnyDescriptionExists) {
        return (
          <TruncatableTextMolecule
            className='activity-card__project-description'
            maxCharacters={(() => {
              switch (breakpoint) {
                case 'xs':
                  return 120;
                case 'sm':
                  return 160;
                default:
                  return 200;
              }
            })()}
            variant='body1'>
            {sortSubactivities(activity.subactivities)
              .map((subactivity) => subactivity.description)
              .join(WHITESPACE)}
          </TruncatableTextMolecule>
        );
      }
      return null;
    }
  };

  const renderSkills = () => {
    if (activity.subactivities) {
      return (
        <SkillListMolecule
          className='activity-card__skills'
          skills={
            activity
              ? sortSkillsExperience(calculateSkillsExperience([activity]))
              : []
          }
          maxSkills={(() => {
            switch (breakpoint) {
              case 'xs':
                return 4;
              case 'sm':
                return 6;
              default:
                return 8;
            }
          })()}
        />
      );
    }
  };

  const renderNavLinks = () => {
    const navLinks: NavListMoleculeItem[] = [];

    if (activity.provider && activity.provider.link) {
      navLinks.push({
        image: {
          src: activity.provider.avatar?.src,
          alt: activity.provider.avatar?.alt ?? activity.provider.name,
          shape: activity.provider.avatar?.shape,
        },
        to: { url: activity.provider.link.url } as UrlNavEndpoint,
      });
    }

    if (activity.project && activity.project.links) {
      activity.project.links.forEach((link) => {
        if (activity.project && link.type === COMMON_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: {
              src: activity.project.avatar?.src,
              alt: activity.project.avatar?.alt ?? activity.project.name,
              shape: activity.project.avatar?.shape,
            },
            to: { url: link.url } as UrlNavEndpoint,
          });
        }
        if (link.type === GITLAB_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: gitLabLogo,
            to: toGitLab(link.path),
          });
        }
        if (link.type === GITHUB_SOCIAL_LINK_TYPE) {
          navLinks.push({
            image: gitHubLogo,
            to: toGitHub(link.path),
          });
        }
      });
    }

    if (navLinks.length > 0) {
      return (
        <Box
          className='activity-card__nav-wrapper'
          display='flex'
          justifyContent='flex-end'>
          <NavListMolecule
            className='activity-card__nav'
            navs={navLinks}
            size='medium'
            badge
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <AccordionDetails
      className={`${className} activity-accordion-details`}
      sx={{ padding: 0, marginTop: '16px' }}>
      <Stack
        className='activity-accordion-details__container'
        direction='column'
        width='100%'
        spacing={2}>
        {renderDescription()}
        {renderSkills()}
        {renderNavLinks()}
      </Stack>
    </AccordionDetails>
  );
};

export default DetailsOfActivityAccordionCardMolecule;
