import { useEffect, useRef, useState } from 'react';

type ElementVisibilityOptions = {
  threshold?: number;
};

const useElementStickiness = (options: ElementVisibilityOptions) => {
  const elementRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsSticky(entry.intersectionRatio < 1),
      options,
    );

    let refValue: Element | null = null;

    if (elementRef.current) {
      observer.observe(elementRef.current);
      refValue = elementRef.current;
    }

    return () => {
      if (refValue) {
        observer.unobserve(refValue);
      }
    };
  }, [elementRef, options]);

  return {
    elementRef,
    isSticky,
  };
};

export default useElementStickiness;
