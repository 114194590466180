import DemandingConstructStage from '../../../../../../../tools/constructed/stages/DemandingConstructStage';
import {
  CV_USER_BASE_BUILD_STAGE_NAME,
  CV_USER_POSITION_BUILD_STAGE_NAME,
} from '../CvUserConstructStageName';

type CvUserPositionConstructStage = DemandingConstructStage<
  typeof CV_USER_POSITION_BUILD_STAGE_NAME,
  typeof CV_USER_BASE_BUILD_STAGE_NAME
>;

export const CV_USER_POSITION_CONSTRUCT_STAGE: CvUserPositionConstructStage = {
  name: CV_USER_POSITION_BUILD_STAGE_NAME,
  require: [CV_USER_BASE_BUILD_STAGE_NAME],
};
