import React from 'react';

import { Box } from '@mui/material';

import { TemplateProps } from '../../TemplateProps';

type CvSubpagesUniversalTemplateProps = TemplateProps & {
  children: React.ReactNode;
};

const CvSubpageUniversalTemplate: React.FC<
  CvSubpagesUniversalTemplateProps
> = ({ className = 'cv-subpage-universal-template', children }) => (
  <Box
    className={`${className} cv-subpage-template`}
    width='100%'
    display='flex'
    flexGrow={1}>
    {children}
  </Box>
);

export default CvSubpageUniversalTemplate;
