import CvSkillTagId from '../../../../constructed/fields/CvSkillTagId';
import CvSkillTag from '../../CvSkillTag';
import isCvSkillTag from '../../guards/isCvSkillTag';

const toCvSkillTags = (
  tags?: (CvSkillTagId | CvSkillTag)[],
): CvSkillTag[] | undefined => {
  if (tags?.length === 0) {
    return [];
  }

  const cvSkillTags: CvSkillTag[] = [];
  tags?.forEach((tag) => {
    if (isCvSkillTag(tag)) {
      cvSkillTags.push(tag);
    }
  });

  if (cvSkillTags.length === 0) {
    return undefined;
  }
  return cvSkillTags;
};

export default toCvSkillTags;
