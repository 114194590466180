import CvActivitySubactivitySkillId from '../../../../constructed/fields/CvActivitySubactivitySkillId';
import CvSubactivitySkill from '../../CvSubactivitySkill';
import ConstructedCvSubactivitySkill from '../ConstructedCvSubactivitySkill';
import isConstructedCvSubactivitySkill from '../guards/isConstructedCvSubactivitySkill';
import toCvSubactivitySkill from './toCvSubactivitySkill';

const toCvSubactivitySkills = (
  skills?: (CvActivitySubactivitySkillId | ConstructedCvSubactivitySkill)[],
): CvSubactivitySkill[] | undefined => {
  if (skills?.length === 0) {
    return [];
  }

  const cvSubactivitySkills: CvSubactivitySkill[] = [];
  skills?.forEach((skill) => {
    if (isConstructedCvSubactivitySkill(skill)) {
      cvSubactivitySkills.push(toCvSubactivitySkill(skill));
    }
  });

  if (cvSubactivitySkills.length === 0) {
    return undefined;
  }
  return cvSubactivitySkills;
};

export default toCvSubactivitySkills;
