import React from 'react';

import { Box } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useCvFiltering from '../../../../../hooks/business/cv/useCvFiltering';

import { ComponentProps } from '../../../ComponentProps';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';
import IconButtonAtom from '../../../atoms/buttons/icon/IconButtonAtom';
import FilterDialogMolecule from '../../dialogs/filter/FilterDialogMolecule';
import BadgeAtom from '../../../atoms/badges/BadgeAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { BUTTON_FILTER_TOOLTIP_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type FilterButtonMoleculeProps = ComponentProps;

const FilterButtonMolecule: React.FC<FilterButtonMoleculeProps> = ({
  className,
}) => {
  const { t } = useInternationalization(MOLECULES_LOCALE_NAMESPACE);
  const {
    appliedFilters,
    isAppliedFiltersLoading,
    isAvailableFilterDataLoading,
  } = useCvFiltering();

  const [isDialogOpened, setIsDialogOpened] = React.useState(false);

  const openFilterDialog = () => setIsDialogOpened(true);

  const closeFilterDialog = () => setIsDialogOpened(false);

  const renderWithTheAppliedFiltersCountBadge = (
    icon: React.ReactNode,
  ): React.ReactNode =>
    appliedFilters.length > 0 ? (
      <BadgeAtom
        className='filter-button__applied-filters-count-badge'
        badgeContent={appliedFilters.length}
        overlap='circular'
        color='error'>
        {icon}
      </BadgeAtom>
    ) : (
      icon
    );

  return (
    <Box className={`${className} filter-button`}>
      <TooltipAtom
        className='filter-button__tooltip'
        disabled={isAppliedFiltersLoading || isAvailableFilterDataLoading}
        title={t(BUTTON_FILTER_TOOLTIP_MAPPING)}
        arrow>
        <Box className='filter-button__tooltip-wrapper'>
          <IconButtonAtom
            className='filter-button__button'
            disabled={isAppliedFiltersLoading || isAvailableFilterDataLoading}
            size='small'
            color='primary'
            onClick={openFilterDialog}>
            {renderWithTheAppliedFiltersCountBadge(
              <FilterAlt style={{ width: '36px', height: '36px' }} />,
            )}
          </IconButtonAtom>
        </Box>
      </TooltipAtom>
      <FilterDialogMolecule
        className='filter-button__dialog'
        open={isDialogOpened}
        handleClose={closeFilterDialog}
      />
    </Box>
  );
};

export default FilterButtonMolecule;
