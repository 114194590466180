import { useNavigate } from 'react-router-dom';

import InternalNavEndpoint from '../../tools/navigation/endpoints/InternalNavEndpoint';
import UrlPath from '../../tools/web/url/paths/UrlPath';

import { INTERNAL_NAVIGATION_SCHEME } from '../../tools/navigation/internalNavigationScheme';

interface UseInternalNavigation {
  navToEndpoint(endpoint: InternalNavEndpoint): void;
  navToPath(path: UrlPath): void;
  toRoot: InternalNavEndpoint;
  toActivities: InternalNavEndpoint;
  toSkills: InternalNavEndpoint;
  toPositions: InternalNavEndpoint;
}

const useInternalNavigation = (): UseInternalNavigation => {
  const navigate = useNavigate();

  const navToPath = (path: UrlPath) => {
    navigate(path);
  };

  const navToEndpoint = (endpoint: InternalNavEndpoint) => {
    navigate(endpoint.path);
  };

  return {
    navToEndpoint,
    navToPath,
    toRoot: INTERNAL_NAVIGATION_SCHEME.root,
    toActivities: INTERNAL_NAVIGATION_SCHEME.activities,
    toSkills: INTERNAL_NAVIGATION_SCHEME.skills,
    toPositions: INTERNAL_NAVIGATION_SCHEME.positions,
  };
};

export default useInternalNavigation;
