import TagGroup from '../TagGroup';
import isArray from '../../../../common/guards/isArray';
import isTagGroup from './isTagGroup';

const isTagGroups = (tagGroups: unknown): tagGroups is TagGroup[] =>
  isArray(tagGroups) &&
  tagGroups.length > 0 &&
  tagGroups.every((tagGroup) => isTagGroup(tagGroup));

export default isTagGroups;
