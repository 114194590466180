import React from 'react';

import { Box, Stack } from '@mui/material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useCv from '../../../../../hooks/business/cv/useCv';

import CvActivity from '../../../../../types/business/cv/fields/activity/CvActivity';
import groupActivitiesByHeir from '../../../../../tools/business/cv/group/groupActivitiesByHeir';

import { ComponentProps } from '../../../ComponentProps';

import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';
import EmptyPlaceholderMolecule from '../../../molecules/placeholders/empty/EmptyPlaceholderMolecule';
import ActivityGroupCardMolecule from '../../../molecules/cards/activity/group/ActivityGroupCardMolecule';

import { CV_ACTIVITIES_EMPTY_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';
import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';

type CvActivitiesOrganismProps = ComponentProps;

const CvActivitiesOrganism: React.FC<CvActivitiesOrganismProps> = ({
  className = 'activities-organism',
}) => {
  const { t } = useInternationalization(ORGANISMS_LOCALE_NAMESPACE);
  const { cvActivities } = useCv();

  const renderActivityCards = (activities: CvActivity[]) => (
    <Stack
      className='cv-activities__activities-list'
      width='100%'
      direction='column'
      spacing={2}>
      {groupActivitiesByHeir(activities).map((activityGroup) => (
        <ActivityGroupCardMolecule
          className={`cv-activities__activity-group-${activityGroup[0].id}`}
          key={activityGroup[0].id}
          activities={activityGroup}
        />
      ))}
    </Stack>
  );

  const renderActivities = (activities: CvActivity[]) =>
    activities.length > 0 ? (
      renderActivityCards(activities)
    ) : (
      <EmptyPlaceholderMolecule
        className='cv-activities__no-activities'
        text={t(CV_ACTIVITIES_EMPTY_MAPPING)}
        width='100%'
        height='auto'
        minHeight='200px'
      />
    );

  const renderLoading = () => (
    <LoadingBackgroundMolecule
      className='cv-activities__loading'
      height='auto'
    />
  );

  return (
    <Box
      className={`${className} cv-activities`}
      width='100%'
      display='flex'
      flexGrow={1}
      alignItems='stretch'>
      {cvActivities ? renderActivities(cvActivities) : renderLoading()}
    </Box>
  );
};

export default CvActivitiesOrganism;
