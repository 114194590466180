import React from 'react';

import { IconButton, IconButtonProps } from '@mui/material';

const IconButtonAtom: React.FC<IconButtonProps> = ({
  className,
  id,
  disabled,
  component = 'button',
  color,
  size,
  sx,
  children,
  onClick,
  onTouchStart,
}) => (
  <IconButton
    className={className}
    id={id}
    disabled={disabled}
    component={component}
    color={color}
    size={size}
    sx={sx}
    onClick={onClick}
    onTouchStart={onTouchStart}>
    {children}
  </IconButton>
);

export default IconButtonAtom;
