import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';

import CvUser from '../../../types/business/cv/fields/user/CvUser';
import CvActivity from '../../../types/business/cv/fields/activity/CvActivity';
import isConstructionFinished from '../../../tools/constructed/utils/isConstructionFinished';

interface UseCv {
  cvUser?: CvUser;
  isCvUserLoaded?: boolean;
  isCvUserAvailable?: boolean;
  cvActivities?: CvActivity[];
  isCvActivitiesLoaded?: boolean;
  isCvActivitiesAvailable?: boolean;
}

const useCv = (): UseCv => {
  const { cvUser, cvActivities } = useSelector(
    (state: RootState) => state.cvReducer,
  );

  const isCvUserLoaded = useMemo(
    () => isConstructionFinished(cvUser.initial),
    [cvUser.initial],
  );

  const isCvActivitiesLoaded = useMemo(
    () => isConstructionFinished(cvActivities.initial),
    [cvActivities.initial],
  );

  return {
    cvUser: cvUser.actual,
    isCvUserLoaded,
    isCvUserAvailable: !!cvUser.actual || isCvUserLoaded,
    cvActivities: cvActivities.actual,
    isCvActivitiesLoaded,
    isCvActivitiesAvailable: !!cvActivities.actual || isCvActivitiesLoaded,
  };
};

export default useCv;
