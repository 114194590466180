import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useCurrentBreakpoint from '../../../../../hooks/theme/useCurrentBreakpoint';
import useCv from '../../../../../hooks/business/cv/useCv';

import calculateSkillsExperience, {
  Experience,
} from '../../../../../tools/business/cv/calculate/calculateSkillsExperience';
import sortSkillsExperience from '../../../../../tools/business/cv/sort/sortSkillsExperience';

import { ComponentProps } from '../../../ComponentProps';
import SkillListMolecule from '../../../molecules/lists/skill/SkillListMolecule';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';
import EmptyPlaceholderMolecule from '../../../molecules/placeholders/empty/EmptyPlaceholderMolecule';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import { CV_SKILLS_EMPTY_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';

type CvSkillsOrganismProps = ComponentProps;

const CvSkillsOrganism: React.FC<CvSkillsOrganismProps> = ({
  className = 'cv-skills-organism',
}) => {
  const { t } = useInternationalization(ORGANISMS_LOCALE_NAMESPACE);
  const breakpoint = useCurrentBreakpoint();
  const { cvActivities } = useCv();

  const experience = useMemo(() => {
    if (cvActivities) {
      if (cvActivities.length === 0) {
        return [];
      }

      let isDataAvailable = false;
      for (const activity of cvActivities) {
        const subactivities = activity.subactivities ?? [];
        for (const subactivity of subactivities) {
          if (subactivity.skills) {
            isDataAvailable = true;
            break;
          }
        }
      }

      if (isDataAvailable) {
        return sortSkillsExperience(calculateSkillsExperience(cvActivities));
      }
    }
    return undefined;
  }, [cvActivities]);
  const getSkillsLength = () => {
    switch (breakpoint) {
      case 'xs':
        return 8;
      case 'sm':
        return 10;
      default:
        return 12;
    }
  };

  const renderSkills = (loadedExperience: Experience[]) =>
    loadedExperience.length > 0 ? (
      <Box className='cv-skills__content' width='100%'>
        <PaperBackgroundMolecule
          className='cv-skills__background'
          sx={{ height: 'auto' }}>
          <SkillListMolecule
            className='cv-skills__skills-list'
            skills={loadedExperience}
            size='medium'
            maxSkills={getSkillsLength()}
          />
        </PaperBackgroundMolecule>
      </Box>
    ) : (
      <EmptyPlaceholderMolecule
        className='cv-skills__no-skills'
        text={t(CV_SKILLS_EMPTY_MAPPING)}
        width='100%'
        height='auto'
        minHeight='200px'
      />
    );

  const renderLoading = () => (
    <LoadingBackgroundMolecule className='cv-skills__loading' height='auto' />
  );

  return (
    <Box
      className={`${className} cv-skills`}
      width='100%'
      display='flex'
      flexGrow={1}
      alignItems='stretch'>
      {experience ? renderSkills(experience) : renderLoading()}
    </Box>
  );
};
export default CvSkillsOrganism;
