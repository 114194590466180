import { ComponentProps } from '../../../ComponentProps';
import SkeletonAtom from '../../../atoms/skeletons/SkeletonAtom';
import React from 'react';

type LoadingBackgroundMoleculeProps = ComponentProps & {
  width?: string;
  height: string;
  sx?: object;
};

const LoadingBackgroundMolecule: React.FC<LoadingBackgroundMoleculeProps> = ({
  className,
  width = '100%',
  height,
  sx,
}) => (
  <SkeletonAtom
    className={`${className} loading-background`}
    variant='rounded'
    width={width}
    height={height}
    sx={sx}
  />
);

export default LoadingBackgroundMolecule;
