import ConstructedCvActivity from '../ConstructedCvActivity';
import TagsResource from '../../../../../../../tools/resources/types/business/tags/TagsResource';
import ConstructedCvActivitySubactivity from '../../fields/subactivities/constructed/ConstructedCvActivitySubactivity';
import updateConstructedActivitySubactivityWithTagsResource from '../../fields/subactivities/constructed/operations/updateConstructedActivitySubactivityWithTagsResource';

const updateConstructedActivityWithTagsResource = (
  constructedActivity: ConstructedCvActivity,
  tagsResource: TagsResource,
): ConstructedCvActivity => {
  if (constructedActivity.subactivities?.length === 0) {
    return constructedActivity;
  }

  const subactivities: ConstructedCvActivitySubactivity[] = [];

  constructedActivity.subactivities?.forEach((constructedSubactivity) => {
    subactivities.push(
      updateConstructedActivitySubactivityWithTagsResource(
        constructedSubactivity as ConstructedCvActivitySubactivity,
        tagsResource,
      ),
    );
  });

  if (subactivities.length > 0) {
    constructedActivity.subactivities = subactivities;
  } else {
    constructedActivity.subactivities = undefined;
  }

  return constructedActivity;
};

export default updateConstructedActivityWithTagsResource;
