import ConstructedCvSubactivitySkill from '../ConstructedCvSubactivitySkill';
import CvSubactivitySkill from '../../CvSubactivitySkill';
import toCvSkillTags from '../../fields/tags/constructed/converters/toCvSkillTags';

const toCvSubactivitySkill = (
  constructedCvSubactivitySkill: ConstructedCvSubactivitySkill,
): CvSubactivitySkill => ({
  ...constructedCvSubactivitySkill,
  tags: toCvSkillTags(constructedCvSubactivitySkill.tags),
});

export default toCvSubactivitySkill;
