import React from 'react';

import { Box } from '@mui/material';

import CvActivity from '../../../../../../types/business/cv/fields/activity/CvActivity';

import { ComponentProps } from '../../../../ComponentProps';
import ActivityAccordionCardMolecule from '../accordion/ActivityAccordionCardMolecule';

type ActivityGroupCardMoleculeProps = ComponentProps & {
  activities: CvActivity[];
};

const ActivityGroupCardMolecule: React.FC<ActivityGroupCardMoleculeProps> = ({
  className,
  activities,
}) => {
  const customSx = {
    '& .activity-group-card': {
      '&:first-of-type': {
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      },
      '&:last-of-type': {
        borderBottomRightRadius: '12px',
        borderBottomLeftRadius: '12px',
      },
    },
  };

  return (
    <Box className={`${className} activity-group-card`} sx={customSx}>
      {activities.map((activity) => (
        <ActivityAccordionCardMolecule
          className={`activity-group-card activity-group-card__entry-${activity.id}`}
          key={activity.id}
          activity={activity}
        />
      ))}
    </Box>
  );
};

export default ActivityGroupCardMolecule;
