export const CV_ACTIVITIES_NAME_MAPPING = 'cv.activities.name';
export const CV_ACTIVITIES_EMPTY_MAPPING = 'cv.activities.empty';
export const CV_SKILLS_NAME_MAPPING = 'cv.skills.name';
export const CV_SKILLS_EMPTY_MAPPING = 'cv.skills.empty';
export const CV_POSITIONS_NAME_MAPPING = 'cv.positions.name';
export const CV_POSITIONS_EMPTY_MAPPING = 'cv.positions.empty';
export const NOT_FOUND_HEADER_MAPPING = 'notFound.header';
export const NOT_FOUND_FZF_MAPPING = 'notFound.fzf';
export const NOT_FOUND_BACK_MAPPING = 'notFound.back';
export const FOOTER_COPYRIGHT_MAPPING = 'footer.copyright';
export const FOOTER_CREATED_BY_MAPPING = 'footer.creator.createdBy';
export const FOOTER_CREATED_BY_COMPACT_MAPPING =
  'footer.creator.createdByCompact';
export const FOOTER_CREATED_CREATOR_NAME_MAPPING = 'footer.creator.creatorName';
export const FOOTER_GITLAB_LINK_MAPPING = 'footer.links.gitlab';

export type OrganismsLocaleNamespaceMappings =
  | typeof CV_ACTIVITIES_NAME_MAPPING
  | typeof CV_ACTIVITIES_EMPTY_MAPPING
  | typeof CV_SKILLS_NAME_MAPPING
  | typeof CV_SKILLS_EMPTY_MAPPING
  | typeof CV_POSITIONS_NAME_MAPPING
  | typeof CV_POSITIONS_EMPTY_MAPPING
  | typeof NOT_FOUND_HEADER_MAPPING
  | typeof NOT_FOUND_FZF_MAPPING
  | typeof NOT_FOUND_BACK_MAPPING
  | typeof FOOTER_COPYRIGHT_MAPPING
  | typeof FOOTER_CREATED_BY_MAPPING
  | typeof FOOTER_CREATED_BY_COMPACT_MAPPING
  | typeof FOOTER_CREATED_CREATOR_NAME_MAPPING
  | typeof FOOTER_GITLAB_LINK_MAPPING;
