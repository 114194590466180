import UndemandingConstructStage from '../../../../../../../tools/constructed/stages/UndemandingConstructStage';
import { CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME } from '../CvActivitiesConstructStageName';

type CvActivityBaseConstructStage = UndemandingConstructStage<
  typeof CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME
>;

export const CV_ACTIVITY_BASE_CONSTRUCT_STAGE: CvActivityBaseConstructStage = {
  name: CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME,
};
