import React from 'react';

import { Badge } from '@mui/material';
import { BadgeProps } from '@mui/material/Badge/Badge';

type BadgeAtomProps = BadgeProps & {
  topIndentation?: string;
  leftIndentation?: string;
};

const BadgeAtom: React.FC<BadgeAtomProps> = ({
  className,
  overlap,
  anchorOrigin,
  badgeContent,
  color,
  topIndentation,
  leftIndentation,
  children,
}) => {
  const customSx = {
    '& .MuiBadge-badge': {
      top: topIndentation,
      right: `-${leftIndentation}`,
    },
  };

  return (
    <Badge
      className={className}
      overlap={overlap}
      anchorOrigin={anchorOrigin}
      badgeContent={badgeContent}
      color={color}
      sx={customSx}>
      {children}
    </Badge>
  );
};

export default BadgeAtom;
