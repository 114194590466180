import InternalNavEndpoint from './endpoints/InternalNavEndpoint';
import { InternalNavRule } from './rules/InternalNavRule';

type InternalNavigationScheme = {
  root: InternalNavEndpoint;
  activities: InternalNavEndpoint;
  skills: InternalNavEndpoint;
  positions: InternalNavEndpoint;
  notFound: InternalNavRule;
};

export const INTERNAL_NAVIGATION_SCHEME: InternalNavigationScheme = {
  root: {
    path: '/',
  },
  activities: {
    path: '/activities',
  },
  skills: {
    path: '/skills',
  },
  positions: {
    path: '/positions',
  },
  notFound: {
    rule: '*',
  },
};
