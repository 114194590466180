import SkillId from '../../../../../../../skill/SkillId';
import SkillName from '../../../../../../../skill/SkillName';
import SkillAvatar from '../../../../../../../skill/SkillAvatar';
import SkillResource from '../../../../../../../../../tools/resources/types/business/skills/SkillResource';
import ConstructedCvSubactivitySkill from './constructed/ConstructedCvSubactivitySkill';
import CvSkillTag from './fields/tags/CvSkillTag';

type CvSubactivitySkill = {
  id: SkillId;
  name: SkillName;
  avatar?: SkillAvatar;
  tags?: CvSkillTag[];
};

export const createSkillFromResource = (
  skillResource: SkillResource,
): ConstructedCvSubactivitySkill => ({
  id: skillResource.id,
  name: skillResource.name,
  avatar: skillResource.avatar,
  tags: skillResource.tagIds,
});

export default CvSubactivitySkill;
