import { useSelector } from 'react-redux';

import { RootState } from '../../storage/reducers/rootReducer';

import LoadableResource from '../../tools/resources/types/LoadableResource';
import VersionResource from '../../tools/resources/types/business/version/VersionResource';
import LanguageBundles from '../../types/business/languageBundles/LanguageBundles';
import CvResource from '../../tools/resources/types/business/cv/CvResource';
import UserResource from '../../tools/resources/types/business/users/UserResource';
import ActivitiesResource from '../../tools/resources/types/business/activities/ActivitiesResource';
import SubactivitiesResource from '../../tools/resources/types/business/subactivities/SubactivitiesResource';
import ProjectsResource from '../../tools/resources/types/business/projects/ProjectsResource';
import ProvidersResource from '../../tools/resources/types/business/providers/ProvidersResource';
import PositionsResource from '../../tools/resources/types/business/positions/PositionsResource';
import SkillsResource from '../../tools/resources/types/business/skills/SkillsResource';
import TagsResource from '../../tools/resources/types/business/tags/TagsResource';

interface UseStaticResources {
  version: LoadableResource<VersionResource>;
  versionLoading: boolean;
  languageBundles: LoadableResource<LanguageBundles>;
  languageBundlesLoading: boolean;
  cv: LoadableResource<CvResource>;
  cvLoading: boolean;
  user: LoadableResource<UserResource>;
  userLoading: boolean;
  activities: LoadableResource<ActivitiesResource>;
  activitiesLoading: boolean;
  subactivities: LoadableResource<SubactivitiesResource>;
  subactivitiesLoading: boolean;
  projects: LoadableResource<ProjectsResource>;
  projectsLoading: boolean;
  providers: LoadableResource<ProvidersResource>;
  providersLoading: boolean;
  positions: LoadableResource<PositionsResource>;
  positionsLoading: boolean;
  skills: LoadableResource<SkillsResource>;
  skillsLoading: boolean;
  tags: LoadableResource<TagsResource>;
  tagsLoading: boolean;
}

const useStaticResources = (): UseStaticResources => {
  const {
    version,
    languageBundles,
    cv,
    user,
    activities,
    subactivities,
    projects,
    providers,
    positions,
    skills,
    tags,
  } = useSelector((state: RootState) => state.resourcesReducer);

  return {
    version: version.value,
    versionLoading: version.loading,
    languageBundles: languageBundles.value,
    languageBundlesLoading: languageBundles.loading,
    cv: cv.value,
    cvLoading: cv.loading,
    user: user.value,
    userLoading: user.loading,
    activities: activities.value,
    activitiesLoading: activities.loading,
    subactivities: subactivities.value,
    subactivitiesLoading: subactivities.loading,
    projects: projects.value,
    projectsLoading: projects.loading,
    providers: providers.value,
    providersLoading: providers.loading,
    positions: positions.value,
    positionsLoading: positions.loading,
    skills: skills.value,
    skillsLoading: skills.loading,
    tags: tags.value,
    tagsLoading: tags.loading,
  };
};

export default useStaticResources;
