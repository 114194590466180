import React from 'react';

import CvSubpageUniversalTemplate from '../../../../templates/cv/subpages/CvSubpageUniversalTemplate';
import CvSkillsOrganism from '../../../../components/organisms/cv/skills/CvSkillsOrganism';

const CvSkillsSubpage: React.FC = () => (
  <CvSubpageUniversalTemplate className='cv-skills__universal-template'>
    <CvSkillsOrganism className='cv-skills-organism' />
  </CvSubpageUniversalTemplate>
);

export default CvSkillsSubpage;
