import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import useAppLanguage from '../internationalization/useAppLanguage';

import { RootState } from '../../storage/reducers/rootReducer';
import useRootDispatch from '../../storage/dispatch/rootDispatch';
import { ResourcesStoreEntry } from '../../storage/reducers/resourcesReducer';
import { LoadResource } from '../../storage/actions/resources/loadResource';
import { default as loadLanguageBundlesResourceAction } from '../../storage/actions/resources/load/loadLanguageBundlesResource';
import { default as loadCvResourceAction } from '../../storage/actions/resources/load/loadCvResource';
import { default as loadUserResourceAction } from '../../storage/actions/resources/load/loadUserResource';
import { default as loadActivitiesResourceAction } from '../../storage/actions/resources/load/loadActivitiesResource';
import { default as loadSubactivitiesResourceAction } from '../../storage/actions/resources/load/loadSubactivitiesResource';
import { default as loadProjectsResourceAction } from '../../storage/actions/resources/load/loadProjectsResource';
import { default as loadProvidersResourceAction } from '../../storage/actions/resources/load/loadProvidersResource';
import { default as loadPositionsResourceAction } from '../../storage/actions/resources/load/loadPositionsResource';
import { default as loadSkillsResourceAction } from '../../storage/actions/resources/load/loadSkillsResource';
import { default as loadTagsResourceAction } from '../../storage/actions/resources/load/loadTagsResource';

import Resource from '../../tools/resources/types/Resource';
import ActivitiesResource from '../../tools/resources/types/business/activities/ActivitiesResource';
import SubactivitiesResource from '../../tools/resources/types/business/subactivities/SubactivitiesResource';
import ProjectsResource from '../../tools/resources/types/business/projects/ProjectsResource';
import ProvidersResource from '../../tools/resources/types/business/providers/ProvidersResource';
import PositionsResource from '../../tools/resources/types/business/positions/PositionsResource';
import SkillsResource from '../../tools/resources/types/business/skills/SkillsResource';
import CvResource from '../../tools/resources/types/business/cv/CvResource';
import UserResource from '../../tools/resources/types/business/users/UserResource';
import LanguageBundles from '../../types/business/languageBundles/LanguageBundles';
import TagsResource from '../../tools/resources/types/business/tags/TagsResource';

/**
 * @see useStaticResourcesLoader - Producer of the useStaticResourcesLoaderEffects events
 */
const useStaticResourcesLoaderEffects = () => {
  const dispatch = useRootDispatch();
  const { getLanguageToBundleAddon } = useAppLanguage();

  const {
    languageBundles,
    cv,
    user,
    activities,
    subactivities,
    projects,
    providers,
    positions,
    skills,
    tags,
  } = useSelector((state: RootState) => state.resourcesReducer);

  const loadResource = useCallback(
    <T extends Resource>(
      resource: ResourcesStoreEntry<T>,
      action: (
        dispatch: Dispatch<LoadResource<string, string, string, T>>,
      ) => Promise<void>,
    ) => {
      if (!resource.value && !resource.loading && !resource.error) {
        dispatch(action);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      cv.required ||
      user.required ||
      activities.required ||
      subactivities.required ||
      projects.required ||
      providers.required ||
      positions.required ||
      skills.required ||
      tags.required
    ) {
      loadResource<LanguageBundles>(
        languageBundles,
        loadLanguageBundlesResourceAction(),
      );
    }
  }, [
    languageBundles,
    activities.required,
    cv.required,
    positions.required,
    projects.required,
    providers.required,
    skills.required,
    subactivities.required,
    user.required,
    tags.required,
    loadResource,
  ]);

  useEffect(() => {
    if (languageBundles.value && cv.required) {
      loadResource<CvResource>(
        cv,
        loadCvResourceAction(
          getLanguageToBundleAddon(languageBundles.value.bundleAddons.cv),
        ),
      );
    }
  }, [languageBundles, cv, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && user.required) {
      loadResource<UserResource>(
        user,
        loadUserResourceAction(
          getLanguageToBundleAddon(languageBundles.value.bundleAddons.user),
        ),
      );
    }
  }, [languageBundles, user, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && activities.required) {
      loadResource<ActivitiesResource>(
        activities,
        loadActivitiesResourceAction(
          getLanguageToBundleAddon(
            languageBundles.value.bundleAddons.activities,
          ),
        ),
      );
    }
  }, [languageBundles, activities, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && subactivities.required) {
      loadResource<SubactivitiesResource>(
        subactivities,
        loadSubactivitiesResourceAction(
          getLanguageToBundleAddon(
            languageBundles.value.bundleAddons.subactivities,
          ),
        ),
      );
    }
  }, [languageBundles, subactivities, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && projects.required) {
      loadResource<ProjectsResource>(
        projects,
        loadProjectsResourceAction(
          getLanguageToBundleAddon(languageBundles.value.bundleAddons.projects),
        ),
      );
    }
  }, [languageBundles, projects, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && providers.required) {
      loadResource<ProvidersResource>(
        providers,
        loadProvidersResourceAction(
          getLanguageToBundleAddon(
            languageBundles.value.bundleAddons.providers,
          ),
        ),
      );
    }
  }, [languageBundles, providers, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && positions.required) {
      loadResource<PositionsResource>(
        positions,
        loadPositionsResourceAction(
          getLanguageToBundleAddon(
            languageBundles.value.bundleAddons.positions,
          ),
        ),
      );
    }
  }, [languageBundles, positions, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && skills.required) {
      loadResource<SkillsResource>(
        skills,
        loadSkillsResourceAction(
          getLanguageToBundleAddon(languageBundles.value.bundleAddons.skills),
        ),
      );
    }
  }, [languageBundles, skills, loadResource, getLanguageToBundleAddon]);

  useEffect(() => {
    if (languageBundles.value && tags.required) {
      loadResource<TagsResource>(
        tags,
        loadTagsResourceAction(
          getLanguageToBundleAddon(languageBundles.value.bundleAddons.tags),
        ),
      );
    }
  }, [languageBundles, tags, loadResource, getLanguageToBundleAddon]);
};

export default useStaticResourcesLoaderEffects;
