export const CV_USER_BASE_BUILD_STAGE_NAME = 'CV_USER/BASE';
export const CV_USER_POSITION_BUILD_STAGE_NAME = 'CV_USER/POSITION';
export const CV_USER_TAGS_BUILD_STAGE_NAME = 'CV_USER/TAGS';

type CvUserConstructStageName =
  | typeof CV_USER_BASE_BUILD_STAGE_NAME
  | typeof CV_USER_POSITION_BUILD_STAGE_NAME
  | typeof CV_USER_TAGS_BUILD_STAGE_NAME;

export default CvUserConstructStageName;
