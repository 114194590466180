import React from 'react';

import { SkeletonProps } from '@mui/material/Skeleton/Skeleton';
import { Skeleton } from '@mui/material';

const SkeletonAtom: React.FC<SkeletonProps> = ({
  className,
  variant,
  width,
  height,
  sx,
}) => (
  <Skeleton
    className={className}
    variant={variant}
    width={width}
    height={height}
    sx={sx}
  />
);

export default SkeletonAtom;
