import requireResource, { RequireResource } from '../requireResource';
import { LOAD_TAGS_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireTagsResource = RequireResource<
  typeof LOAD_TAGS_RESOURCE_REQUIREMENT
>;

const requireSkillsResource = () =>
  requireResource(LOAD_TAGS_RESOURCE_REQUIREMENT);

export default requireSkillsResource;
