import ConstructedCvSubactivitySkill from '../ConstructedCvSubactivitySkill';
import TagsResource from '../../../../../../../../../../../tools/resources/types/business/tags/TagsResource';
import CvSkillTag from '../../fields/tags/CvSkillTag';
import CvSkillTagId from '../fields/CvSkillTagId';

const updateConstructedSubactivitySkillWithTagsResource = (
  constructedSkill: ConstructedCvSubactivitySkill,
  tagsResource: TagsResource,
): ConstructedCvSubactivitySkill => {
  if (constructedSkill.tags?.length === 0) {
    return constructedSkill;
  }

  const tags: CvSkillTag[] = [];

  constructedSkill.tags?.forEach((tag) => {
    const foundTagResource = tagsResource.find(
      (tagResource) => (tag as CvSkillTagId) === tagResource.id,
    );
    if (foundTagResource) {
      tags.push(foundTagResource);
    }
  });

  if (tags.length > 0) {
    constructedSkill.tags = tags;
  } else {
    constructedSkill.tags = undefined;
  }

  return constructedSkill;
};

export default updateConstructedSubactivitySkillWithTagsResource;
