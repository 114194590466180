import React from 'react';

import { ExpandMore } from '@mui/icons-material';

import useAppTheme from '../../../../../hooks/theme/useAppTheme';

import { ComponentProps } from '../../../ComponentProps';
import IconButtonAtom from '../../../atoms/buttons/icon/IconButtonAtom';

type ExpandButtonMoleculeProps = ComponentProps & {
  expanded?: boolean;
  onClick?: () => void;
};

const ExpandButtonMolecule: React.FC<ExpandButtonMoleculeProps> = ({
  className,
  expanded,
  onClick,
}) => {
  const { appTheme } = useAppTheme();

  return (
    <IconButtonAtom
      className={`${className} expand-button`}
      color='primary'
      sx={{
        marginLeft: 'auto',
        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: appTheme.transitions.create('transform', {
          duration: appTheme.transitions.duration.shortest,
        }),
      }}
      onClick={onClick}>
      <ExpandMore />
    </IconButtonAtom>
  );
};

export default ExpandButtonMolecule;
