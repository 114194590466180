import UserResource from '../../../../../../../tools/resources/types/business/users/UserResource';
import ConstructedCvUser from '../ConstructedCvUser';

const createConstructedUserFromResource = (
  userResource: UserResource,
): ConstructedCvUser => ({
  id: userResource.id,
  avatar: userResource.avatar,
  age: userResource.age,
  position: userResource.currentPositionId,
  firstName: userResource.firstName,
  middleName: userResource.middleName,
  lastName: userResource.lastName,
  location: userResource.location,
  social: userResource.social,
  preferences: {
    contacts: userResource.preferences?.contacts,
    tagGroups: userResource.preferences?.tagGroups?.map((group) => ({
      name: group.name,
      tags: group.tagIds,
    })),
  },
});

export default createConstructedUserFromResource;
