import React from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

type TooltipAtomProps = TooltipProps & {
  disabled?: boolean;
};

const TooltipAtom: React.FC<TooltipAtomProps> = ({
  className,
  title,
  arrow,
  open,
  onClose,
  onMouseEnter,
  onMouseLeave,
  disabled = false,
  disableHoverListener,
  disableFocusListener,
  disableTouchListener,
  enterDelay,
  leaveDelay,
  children,
}) => (
  <Tooltip
    className={className}
    title={title}
    arrow={arrow}
    open={open}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    disableHoverListener={disabled || disableHoverListener}
    disableFocusListener={disabled || disableFocusListener}
    disableTouchListener={disabled || disableTouchListener}
    enterDelay={enterDelay}
    leaveDelay={leaveDelay}
    onClose={onClose}>
    {children}
  </Tooltip>
);

export default TooltipAtom;
