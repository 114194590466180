import React from 'react';

import CvSubpageUniversalTemplate from '../../../../templates/cv/subpages/CvSubpageUniversalTemplate';
import CvActivitiesOrganism from '../../../../components/organisms/cv/activities/CvActivitiesOrganism';

const CvActivitiesSubpage: React.FC = () => (
  <CvSubpageUniversalTemplate className='cv-activities__universal-template'>
    <CvActivitiesOrganism className='cv-activities-organism' />
  </CvSubpageUniversalTemplate>
);

export default CvActivitiesSubpage;
