import React from 'react';

import { Box } from '@mui/material';

import { ComponentProps } from '../../../ComponentProps';

type TransparentBackgroundMoleculeProps = ComponentProps & {
  component?: React.ElementType;
  sx?: object;
  children?: React.ReactNode;
};

const DefaultBackgroundMolecule = React.forwardRef(
  (
    {
      className,
      component = 'div',
      sx,
      children,
    }: TransparentBackgroundMoleculeProps,
    ref,
  ) => (
    <Box
      className={`${className} default-background`}
      ref={ref}
      component={component}
      sx={{
        width: '100%',
        padding: '24px',
        backgroundColor: 'background.default',
        ...sx,
      }}>
      {children}
    </Box>
  ),
);

export default DefaultBackgroundMolecule;
