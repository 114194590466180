import ActivityResource from '../../../../../../../tools/resources/types/business/activities/ActivityResource';
import ConstructedCvActivity from '../ConstructedCvActivity';
import isProjectActivityResource from '../../../../../../../tools/resources/types/business/activities/guards/isProjectActivityResource';
import isProviderActivityResource from '../../../../../../../tools/resources/types/business/activities/guards/isProviderActivityResource';

const createConstructedActivityFromResource = (
  activityResource: ActivityResource,
): ConstructedCvActivity => ({
  id: activityResource.id,
  heirId: activityResource.heirId,
  type: activityResource.type,
  period: activityResource.period,
  position: activityResource.positionId,
  project: isProjectActivityResource(activityResource)
    ? activityResource.projectId
    : undefined,
  provider: isProviderActivityResource(activityResource)
    ? activityResource.providerId
    : undefined,
  subactivities: activityResource.subactivitiesIds,
});

export default createConstructedActivityFromResource;
