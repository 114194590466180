import { CV_USER_BASE_BUILD_STAGE_NAME } from '../CvUserConstructStageName';
import UndemandingConstructStage from '../../../../../../../tools/constructed/stages/UndemandingConstructStage';

type CvUserBaseConstructStage = UndemandingConstructStage<
  typeof CV_USER_BASE_BUILD_STAGE_NAME
>;

export const CV_USER_BASE_CONSTRUCT_STAGE: CvUserBaseConstructStage = {
  name: CV_USER_BASE_BUILD_STAGE_NAME,
};
