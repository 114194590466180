import React from 'react';

import CvSubpageUniversalTemplate from '../../../../templates/cv/subpages/CvSubpageUniversalTemplate';
import CvPositionsOrganism from '../../../../components/organisms/cv/positions/CvPositionsOrganism';

const CvPositionsSubpage: React.FC = () => (
  <CvSubpageUniversalTemplate className='cv-positions__universal-template'>
    <CvPositionsOrganism className='cv-positions-organism' />
  </CvSubpageUniversalTemplate>
);

export default CvPositionsSubpage;
