import { Dispatch } from 'redux';

export type SetValue<T, P> = {
  type: T;
  payload: P;
};

export type SetOptionalValue<T, P> = SetValue<T, P | undefined>;

const setValue =
  <T, P>(type: T, payload: P) =>
  (dispatch: Dispatch<SetValue<T, P>>) => {
    dispatch({ type, payload });
  };

export default setValue;
