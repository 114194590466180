import CvUser from '../../CvUser';
import ConstructedCvUser from '../ConstructedCvUser';
import isPosition from '../../../../../position/guards/isPosition';
import isTagGroups from '../../../../../tag/group/guards/isTagGroups';

const toCvUser = (constructedCvUser?: ConstructedCvUser): CvUser | undefined =>
  constructedCvUser && {
    ...constructedCvUser,
    position: isPosition(constructedCvUser.position)
      ? constructedCvUser.position
      : undefined,
    preferences: constructedCvUser.preferences && {
      contacts: constructedCvUser.preferences.contacts,
      tagGroups: isTagGroups(constructedCvUser.preferences.tagGroups)
        ? constructedCvUser.preferences.tagGroups
        : undefined,
    },
  };

export default toCvUser;
