import Url from '../web/url/Url';
import FetchRequest from '../web/fetcher/request/FetchRequest';
import FetchHeaders, {
  ACCEPT_JSON,
  ACCEPT_TEXT,
  FetchHeaderAccept,
} from '../web/fetcher/request/FetchHeaders';
import fetcher from '../web/fetcher/fetcher';
import I18nLanguage from '../internationalization/i18n/types/I18nLanguage';
import {
  JSON_RESOURCE,
  TEXT_RESOURCE,
} from '../resources/object/fields/extension/FileResourceExtension';
import RESOURCES from '../resources/resources';
import buildResourceUrl from '../resources/utils/buildResourceUrl';
import buildResourcePath from '../resources/utils/buildResourcePath';
import ResourceObject from '../resources/object/ResourceObject';
import VersionResource from '../resources/types/business/version/VersionResource';
import CvResource from '../resources/types/business/cv/CvResource';
import UserResource from '../resources/types/business/users/UserResource';
import ActivitiesResource from '../resources/types/business/activities/ActivitiesResource';
import ProjectsResource from '../resources/types/business/projects/ProjectsResource';
import ProvidersResource from '../resources/types/business/providers/ProvidersResource';
import PositionsResource from '../resources/types/business/positions/PositionsResource';
import SkillsResource from '../resources/types/business/skills/SkillsResource';
import SubactivitiesResource from '../resources/types/business/subactivities/SubactivitiesResource';
import LanguageBundlesResource from '../resources/types/business/languageBundles/LanguageBundlesResource';
import TagsResource from '../resources/types/business/tags/TagsResource';

type I18nResourceObject = {
  object: ResourceObject;
  parameters: I18nLanguage[];
};

const buildResourceRequestUrl = (resource: I18nResourceObject): Url =>
  buildResourceUrl(buildResourcePath(resource.object, ...resource.parameters));

const buildAcceptHeadersPart = (
  resource: ResourceObject,
): FetchHeaderAccept => {
  let accept: FetchHeaderAccept = ACCEPT_TEXT;

  if (resource.type === TEXT_RESOURCE) {
    accept = ACCEPT_TEXT;
  }
  if (resource.type === JSON_RESOURCE) {
    accept = ACCEPT_JSON;
  }

  return accept;
};

const buildResourceRequestHeaders = (
  resource: ResourceObject,
): FetchHeaders => ({
  Accept: buildAcceptHeadersPart(resource),
});

const buildResourceRequest = (resource: I18nResourceObject): FetchRequest => ({
  url: buildResourceRequestUrl(resource),
  headers: buildResourceRequestHeaders(resource.object),
});

const getResource = async <T>(resource: I18nResourceObject) => {
  try {
    return await fetcher.get<T>(buildResourceRequest(resource));
  } catch (e) {
    const errorMsg = `Resource '${
      resource.object.name
    }' was not found in path ${buildResourceRequestUrl(resource)}!`;
    console.error(errorMsg);
    throw new Error(errorMsg);
  }
};

const getVersion = () =>
  getResource<VersionResource>({
    object: RESOURCES.version,
    parameters: [],
  });

const getLanguageBundles = () =>
  getResource<LanguageBundlesResource>({
    object: RESOURCES.languageBundles,
    parameters: [],
  });

const getCv = (language: I18nLanguage) =>
  getResource<CvResource>({
    object: RESOURCES.cv,
    parameters: [language],
  });

const getUser = (language: I18nLanguage) =>
  getResource<UserResource>({ object: RESOURCES.user, parameters: [language] });

const getActivities = (language: I18nLanguage) =>
  getResource<ActivitiesResource>({
    object: RESOURCES.activities,
    parameters: [language],
  });

const getSubactivities = (language: I18nLanguage) =>
  getResource<SubactivitiesResource>({
    object: RESOURCES.subactivities,
    parameters: [language],
  });

const getProjects = (language: I18nLanguage) =>
  getResource<ProjectsResource>({
    object: RESOURCES.projects,
    parameters: [language],
  });

const getProviders = (language: I18nLanguage) =>
  getResource<ProvidersResource>({
    object: RESOURCES.providers,
    parameters: [language],
  });

const getPositions = (language: I18nLanguage) =>
  getResource<PositionsResource>({
    object: RESOURCES.positions,
    parameters: [language],
  });

const getSkills = (language: I18nLanguage) =>
  getResource<SkillsResource>({
    object: RESOURCES.skills,
    parameters: [language],
  });

const getTags = (language: I18nLanguage) =>
  getResource<TagsResource>({
    object: RESOURCES.tags,
    parameters: [language],
  });

const RESOURCES_API = {
  getVersion,
  getLanguageBundles,
  getCv,
  getUser,
  getActivities,
  getSubactivities,
  getProjects,
  getProviders,
  getPositions,
  getSkills,
  getTags,
};

export default RESOURCES_API;
