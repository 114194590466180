import ConstructedCvUser from '../ConstructedCvUser';
import TagsResource from '../../../../../../../tools/resources/types/business/tags/TagsResource';
import CvUserPreferencesTagGroup from '../fields/CvUserPreferencesTagGroup';
import TagsUserPreference from '../../../../../user/preferences/TagsUserPreference';
import TagResource from '../../../../../../../tools/resources/types/business/tags/TagResource';
import TagId from '../../../../../tag/TagId';
import TagGroup from '../../../../../tag/group/TagGroup';
import isTagGroups from '../../../../../tag/group/guards/isTagGroups';

const createTagGroupsFromTagIds = (
  constructedTagGroups:
    | CvUserPreferencesTagGroup[]
    | TagsUserPreference
    | undefined,
  tagsResource: TagsResource,
): TagsUserPreference | undefined => {
  if (!constructedTagGroups) {
    return undefined;
  }

  if (isTagGroups(constructedTagGroups)) {
    return constructedTagGroups;
  }

  const tagsMappedByIds = new Map<TagId, TagResource>();
  tagsResource.forEach((tagResource) =>
    tagsMappedByIds.set(tagResource.id, tagResource),
  );

  const resultTagGroups: TagGroup[] = [];
  constructedTagGroups.forEach((tagGroup) => {
    const resultGroupTags: TagsResource = [];
    tagGroup.tags.forEach((tagId) => {
      const tag = tagsMappedByIds.get(tagId);
      if (tag) {
        resultGroupTags.push(tag);
      }
    });
    resultTagGroups.push({ name: tagGroup.name, tags: resultGroupTags });
  });

  return resultTagGroups;
};

const updateConstructedUserWithTagsResource = (
  constructedUser: ConstructedCvUser,
  tagsResource: TagsResource,
): ConstructedCvUser => ({
  ...constructedUser,
  preferences: {
    ...constructedUser.preferences,
    tagGroups: createTagGroupsFromTagIds(
      constructedUser.preferences?.tagGroups,
      tagsResource,
    ),
  },
});

export default updateConstructedUserWithTagsResource;
