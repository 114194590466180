import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setAppliedFilters from '../../../storage/actions/cv/set/filters/setAppliedFilters';
import setActualCvActivities from '../../../storage/actions/cv/set/activities/setActualCvActivities';

import Filter from '../../../types/business/filter/common/Filter';
import FilterId from '../../../types/business/filter/common/prototype/fields/FilterId';
import FilterData from '../../../types/business/filter/data/FilterData';

import generateFilters from '../../../tools/business/cv/filter/generateFilters';
import sortFilters from '../../../tools/business/cv/filter/sortFilters';

interface UseCvFiltering {
  appliedFilters: Filter[];
  isAppliedFiltersLoading: boolean;
  availableFilterData: FilterData[];
  isAvailableFilterDataLoading: boolean;
  applyNewFilters: (newFilterValues: FilterData[]) => void;
  removeFilter: (filterId: FilterId) => void;
}

/**
 * @see useCvFilteringEffects - Receiver of the useCvFiltering events
 */
const useCvFiltering = (): UseCvFiltering => {
  const dispatch = useRootDispatch();
  const { filters } = useSelector((state: RootState) => state.cvReducer);

  const applyNewFilters = (newFilterData: FilterData[]) => {
    dispatch(setActualCvActivities(undefined));
    dispatch(setAppliedFilters({ loading: true, value: [] }));

    const newFilters = generateFilters(newFilterData);
    const newFiltersSorted = sortFilters(newFilters);

    dispatch(setAppliedFilters({ loading: false, value: newFiltersSorted }));
  };

  const removeFilter = (filterId: FilterId) => {
    dispatch(setActualCvActivities(undefined));
    dispatch(
      setAppliedFilters({ loading: true, value: filters.applied.value }),
    );

    const newFilters = filters.applied.value.filter(
      (filter) => filter.id !== filterId,
    );

    dispatch(setAppliedFilters({ loading: false, value: newFilters }));
  };

  return {
    appliedFilters: filters.applied.value,
    isAppliedFiltersLoading: filters.applied.loading,
    availableFilterData: filters.available.value,
    isAvailableFilterDataLoading: filters.available.loading,
    applyNewFilters,
    removeFilter,
  };
};

export default useCvFiltering;
