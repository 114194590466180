import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setActualCvActivities from '../../../storage/actions/cv/set/activities/setActualCvActivities';
import setAvailableFilters from '../../../storage/actions/cv/set/filters/setAvailableFilters';

import useCvLoader from './useCvLoader';

import filterActivities from '../../../tools/business/cv/filter/filterActivities';
import generateAvailableFilterData from '../../../tools/business/cv/filter/generateAvailableFilterData';

/**
 * @see useCvFiltering - Producer of the useCvFilteringEffects events
 */
const useCvFilteringEffects = () => {
  const dispatch = useRootDispatch();
  const { filters } = useSelector((state: RootState) => state.cvReducer);

  const { isCvActivitiesLoaded, initialCvActivities } = useCvLoader();

  useEffect(() => {
    dispatch(
      setAvailableFilters({
        loading: !isCvActivitiesLoaded,
        value: generateAvailableFilterData(initialCvActivities),
      }),
    );
  }, [isCvActivitiesLoaded, initialCvActivities, dispatch]);

  useEffect(() => {
    if (isCvActivitiesLoaded && !filters.applied.loading) {
      dispatch(
        setActualCvActivities(
          filterActivities(filters.applied.value, initialCvActivities),
        ),
      );
    }
  }, [initialCvActivities, filters.applied, isCvActivitiesLoaded, dispatch]);
};

export default useCvFilteringEffects;
