import ActivityId from '../../../../types/business/activity/ActivityId';
import CvActivity from '../../../../types/business/cv/fields/activity/CvActivity';

import sortActivities from '../sort/sortActivities';

const createActivityGroupForHeirActivity = (
  heirActivity: CvActivity,
  activitiesGroupedByHeirId: Map<ActivityId, CvActivity>,
): CvActivity[] => {
  const predecessorActivity = activitiesGroupedByHeirId.get(heirActivity.id);

  if (predecessorActivity) {
    return [
      heirActivity,
      ...createActivityGroupForHeirActivity(
        predecessorActivity,
        activitiesGroupedByHeirId,
      ),
    ];
  } else {
    return [heirActivity];
  }
};

const groupActivitiesByHeir = (activities: CvActivity[]): CvActivity[][] => {
  const activitiesGroupedByHeirId = new Map<ActivityId, CvActivity>();
  const activitiesWithoutHeir: CvActivity[] = [];
  activities.forEach((activity) => {
    if (activity.heirId) {
      activitiesGroupedByHeirId.set(activity.heirId, activity);
    } else {
      activitiesWithoutHeir.push(activity);
    }
  });

  const sortedActivitiesWithoutHeir = sortActivities(activitiesWithoutHeir);

  return sortedActivitiesWithoutHeir.map((activityWithoutHeir) =>
    createActivityGroupForHeirActivity(
      activityWithoutHeir,
      activitiesGroupedByHeirId,
    ),
  );
};

export default groupActivitiesByHeir;
