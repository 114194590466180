import {
  CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME,
  CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE_NAME,
} from '../CvActivitiesConstructStageName';
import DemandingConstructStage from '../../../../../../../tools/constructed/stages/DemandingConstructStage';

type CvActivityProviderConstructStage = DemandingConstructStage<
  typeof CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE_NAME,
  typeof CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME
>;

export const CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE: CvActivityProviderConstructStage =
  {
    name: CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE_NAME,
    require: [CV_ACTIVITY_BASE_CONSTRUCT_STAGE_NAME],
  };
