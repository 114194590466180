import React from 'react';

import { Tab, TabProps } from '@mui/material';

type TabAtomProps = TabProps & {
  minHeight?: string;
  minWidth?: string;
};

const TabAtom: React.FC<TabAtomProps> = ({
  className,
  key,
  value,
  label,
  wrapped,
  icon,
  iconPosition,
  sx,
  minHeight = '54px',
  minWidth,
  children,
  ...props
}) => {
  const styledSx = {
    '&.MuiTab-root': {
      minHeight,
      marginX: '1px',
      textTransform: 'none',
      fontSize: '1.25rem',
      letterSpacing: '0em',
      backgroundColor: 'primary.phantom',
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.ghost',
    },
    ...sx,
  };

  return (
    <Tab
      className={className}
      key={key}
      value={value}
      label={label}
      wrapped={wrapped}
      icon={icon}
      iconPosition={iconPosition}
      sx={styledSx}
      {...props}>
      {children}
    </Tab>
  );
};

export default TabAtom;
