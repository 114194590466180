import React from 'react';

import { Grid } from '@mui/material';

import CvUser from '../../../../../../types/business/cv/fields/user/CvUser';

import { ComponentProps } from '../../../../ComponentProps';
import TypographyAtom from '../../../../atoms/typography/TypographyAtom';
import GeolocationLinkMolecule from '../../../links/geolocation/GeolocationLinkMolecule';

type UserInformationGridMoleculeProps = ComponentProps & {
  user: CvUser;
};

const UserInformationGridMolecule: React.FC<
  UserInformationGridMoleculeProps
> = ({ className, user }) => {
  const renderNameAge = () => (
    <Grid item>
      <TypographyAtom
        className='user-information__name-age'
        component='h5'
        variant='h5'>
        {user.firstName
          .concat(user.middleName ? ` ${user.middleName}` : '')
          .concat(user.lastName ? ` ${user.lastName}` : '')
          .concat(user.age ? `, ${user.age}` : '')}
      </TypographyAtom>
    </Grid>
  );

  const renderPosition = () => {
    if (user.position) {
      return (
        <Grid item>
          <TypographyAtom
            className='user-information__position'
            component='h5'
            variant='h5'
            sx={{ paddingTop: '2px' }}>
            {user.position.name}
          </TypographyAtom>
        </Grid>
      );
    }
  };

  const renderLocation = () => {
    if (user.location) {
      return (
        <Grid item>
          <GeolocationLinkMolecule
            className='user-information__location-link'
            location={user.location}
          />
        </Grid>
      );
    }
  };

  return (
    <Grid
      className={`${className} user-information`}
      item
      container
      direction='column'
      rowSpacing={1.25}>
      {renderNameAge()}
      {renderPosition()}
      {renderLocation()}
    </Grid>
  );
};

export default UserInformationGridMolecule;
