import React from 'react';

import { Accordion } from '@mui/material';

import CvActivity from '../../../../../../types/business/cv/fields/activity/CvActivity';
import { ComponentProps } from '../../../../ComponentProps';
import SummaryOfActivityAccordionCardMolecule from './parts/SummaryOfActivityAccordionCardMolecule';
import DetailsOfActivityAccordionCardMolecule from './parts/DetailsOfActivityAccordionCardMolecule';

type ActivityAccordionCardMoleculeProps = ComponentProps & {
  activity: CvActivity;
};

const ActivityAccordionCardMolecule: React.FC<
  ActivityAccordionCardMoleculeProps
> = ({ className, activity }) => {
  const [isCardExpanded, setIsCardExpanded] = React.useState(false);

  const switchCardExpand = () => {
    setIsCardExpanded(!isCardExpanded);
  };

  return (
    <Accordion
      className={`${className} activity-accordion-card`}
      key={activity.id}
      expanded={isCardExpanded}
      onChange={(_, __) => switchCardExpand()}
      elevation={0}
      sx={{ width: '100%', padding: '24px' }}>
      <SummaryOfActivityAccordionCardMolecule
        className='activity-accordion-card__summary'
        activity={activity}
        expanded={isCardExpanded}
        onExpand={switchCardExpand}
      />
      <DetailsOfActivityAccordionCardMolecule
        className='activity-accordion-card__details'
        activity={activity}
      />
    </Accordion>
  );
};

export default ActivityAccordionCardMolecule;
