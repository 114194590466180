import React from 'react';

import { Box, Grid } from '@mui/material';

import useCv from '../../../../../hooks/business/cv/useCv';

import CvUser from '../../../../../types/business/cv/fields/user/CvUser';

import { ComponentProps } from '../../../ComponentProps';
import AvatarImageMolecule from '../../../molecules/images/avatar/AvatarImageMolecule';
import UserInformationGridMolecule from '../../../molecules/grids/user/information/UserInformationGridMolecule';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';
import UserSocialListMolecule from '../../../molecules/lists/user/social/UserSocialListMolecule';

type UserCvOrganismProps = ComponentProps;

const CvUserOrganism: React.FC<UserCvOrganismProps> = ({
  className = 'cv-user-organism',
}) => {
  const { cvUser } = useCv();

  const renderContent = (loadedUser: CvUser) => (
    <PaperBackgroundMolecule className='user-cv__background'>
      <Grid
        className='user-cv__content'
        width='100%'
        container
        columnSpacing={{ xs: 0, sm: 2 }}
        rowSpacing={{ xs: 2, sm: 0 }}
        justifyContent={{ xs: 'center', sm: 'flex-start' }}>
        <Grid
          className='user-cv__avatar'
          item
          display='flex'
          alignItems='center'>
          <AvatarImageMolecule
            className='user-cv__avatar-image'
            image={loadedUser.avatar}
          />
        </Grid>
        <Grid
          className='user-cv__user-data'
          item
          xs={12}
          sm
          container
          direction='column'
          justifyContent='space-between'
          rowSpacing={2}>
          <UserInformationGridMolecule
            className='user-cv__information'
            user={loadedUser}
          />
          <Grid item>
            <UserSocialListMolecule
              className='user-cv__social'
              social={cvUser?.social}
              preferences={cvUser?.preferences}
            />
          </Grid>
        </Grid>
      </Grid>
    </PaperBackgroundMolecule>
  );

  const renderLoading = () => (
    <LoadingBackgroundMolecule className='user-cv__loading' height='200px' />
  );

  return (
    <Box className={`${className} user-cv`} width='100%'>
      {cvUser ? renderContent(cvUser) : renderLoading()}
    </Box>
  );
};

export default CvUserOrganism;
