import React from 'react';

import { Accordion, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import FilterData from '../../../../../../types/business/filter/data/FilterData';

import { ComponentProps } from '../../../../ComponentProps';
import BadgeAtom from '../../../../atoms/badges/BadgeAtom';
import TypographyAtom from '../../../../atoms/typography/TypographyAtom';

type BaseFilterDialogMoleculeAccordionProps = ComponentProps & {
  id: string;
  isExpanded: boolean;
  title: string;
  data: FilterData[];
  onChangeExpand: () => void;
  children?: React.ReactNode;
};

const BaseFilterDialogMoleculeAccordion: React.FC<
  BaseFilterDialogMoleculeAccordionProps
> = ({ className, id, isExpanded, title, data, onChangeExpand, children }) => {
  const renderAccordionSummary = () => (
    <AccordionSummary
      className={`${className}__summary`}
      expandIcon={<ExpandMore />}
      aria-controls={`${className}-content`}
      id={`${id}-header`}>
      <BadgeAtom
        className={`${className}__summary-filters-count-badge`}
        badgeContent={data.length}
        color='error'
        topIndentation='12px'
        leftIndentation='18px'>
        <TypographyAtom className={`${className}__summary-title`}>
          {title}
        </TypographyAtom>
      </BadgeAtom>
    </AccordionSummary>
  );

  return (
    <Accordion
      className={`${className} filter-dialog-accordion`}
      expanded={isExpanded}
      onChange={onChangeExpand}
      square>
      {renderAccordionSummary()}
      {children}
    </Accordion>
  );
};

export default BaseFilterDialogMoleculeAccordion;
