import ConstructFlow from '../../../../../../tools/constructed/ConstructFlow';
import CvUserConstructStageName from './CvUserConstructStageName';
import { CV_USER_BASE_CONSTRUCT_STAGE } from './stages/CvUserBaseConstructStage';
import { CV_USER_POSITION_CONSTRUCT_STAGE } from './stages/CvUserPositionConstructStage';
import { CV_USER_TAGS_CONSTRUCT_STAGE } from './stages/CvUserTagsConstructStage';

type CvUserConstructFlow = ConstructFlow<
  CvUserConstructStageName,
  typeof CV_USER_BASE_CONSTRUCT_STAGE
>;

export const CV_USER_CONSTRUCT_FLOW: CvUserConstructFlow = [
  CV_USER_BASE_CONSTRUCT_STAGE,
  CV_USER_POSITION_CONSTRUCT_STAGE,
  CV_USER_TAGS_CONSTRUCT_STAGE,
];

export default CvUserConstructFlow;
