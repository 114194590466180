import { Locale } from '../Locale';
import { LOCALE_NAMESPACE_NAME } from '../namespaces/LocaleNamespaceName';

const en: Locale = {
  [LOCALE_NAMESPACE_NAME.APP]: {
    name: 'ICV',
  },
  [LOCALE_NAMESPACE_NAME.ORGANISMS]: {
    cv: {
      activities: {
        name: 'Activities',
        empty:
          'It looks like the user has not shared their activities experience yet.',
      },
      skills: {
        name: 'Skills',
        empty:
          'It looks like the user has not shared their skills experience yet.',
      },
      positions: {
        name: 'Positions',
        empty: 'It looks like the user has not shared their positions yet.',
      },
    },
    notFound: {
      header: 'The page are you looking for does not exist.',
      fzf: '404',
      back: 'To home',
    },
    footer: {
      copyright: '© 2024 ICV',
      creator: {
        createdBy: 'Created by',
        createdByCompact: 'by',
        creatorName: 'Michael Linker',
      },
      links: {
        gitlab: 'Gitlab',
      },
    },
  },
  [LOCALE_NAMESPACE_NAME.MOLECULES]: {
    buttons: {
      theme: {
        tooltip: 'Switch theme',
      },
      contact: {
        text: 'Contact',
      },
      filter: {
        tooltip: 'Setup filters',
      },
      experience: {
        tooltip: 'Show experience',
      },
    },
    cards: {
      activity: {
        type: {
          personal: 'Personal',
          commercial: 'Commercial',
          startup: 'Startup',
          internship: 'Internship',
          education: 'Education',
        },
      },
    },
    chips: {
      filter: {
        placeholder: {
          text: 'Setup filters',
        },
        prefix: {
          activityType: 'By activity type',
          activityPosition: 'By position',
          activityPeriod: 'By period',
        },
        activityPeriod: {
          unknown: 'Unknown',
          lastYear: 'Last year',
          lastFiveYears: 'Last five years',
          custom: {
            from: 'From',
            to: 'to',
            until: 'Until',
          },
        },
      },
    },
    dialogs: {
      contact: {
        title: 'Contact',
        close: 'Close',
        alerts: {
          regular: {
            text: 'The user is actively looking for a job, so it would not take long to get a response',
          },
          warning: {
            title: 'Warning',
            text: 'At the moment, the user is not looking for a job, so the response may take longer than usual',
          },
        },
      },
      filter: {
        title: 'Setup filters',
        close: 'Close',
        apply: 'Apply',
        resetAll: 'Reset all',
        accordion: {
          reset: 'Reset',
          activityType: {
            title: 'By activity type',
          },
          activityPosition: {
            title: 'By position',
          },
          activityPeriod: {
            title: 'By period',
            none: 'None',
            lastYear: 'Last year',
            lastFiveYears: 'Last five years',
            custom: {
              title: 'Custom period',
              from: 'From',
              to: 'To',
            },
          },
        },
      },
    },
    tables: {
      experience: {
        position: 'Position',
        experience: 'Experience',
      },
    },
    text: {
      truncatable: {
        readMore: 'Read more',
      },
      copyable: {
        tooltip: {
          success: 'Copied',
          failure: 'Failed to copy',
        },
      },
    },
  },
  [LOCALE_NAMESPACE_NAME.ASSETS]: {
    media: {
      svg: {
        icvLogo: {
          alt: 'ICV',
        },
        gitlabLogo: {
          alt: 'GitLab',
        },
        githubLogo: {
          alt: 'GitHub',
        },
        linkedinLogo: {
          alt: 'LinkedIn',
        },
        telegramLogo: {
          alt: 'Telegram',
        },
        avatarPlaceholder: {
          alt: 'User default avatar',
        },
        emptyBox: {
          alt: 'Empty box',
        },
      },
    },
  },
  [LOCALE_NAMESPACE_NAME.TIMEDATE]: {
    dates: {
      present: 'Present',
    },
  },
};

export default en;
