import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useCv from '../../../../../hooks/business/cv/useCv';

import calculatePositionsExperience, {
  PositionExperience,
} from '../../../../../tools/business/cv/calculate/calculatePositionsExperience';
import sortPositionsExperience from '../../../../../tools/business/cv/sort/sortPositionsExperience';

import { ComponentProps } from '../../../ComponentProps';
import PositionsTableMolecule from '../../../molecules/tables/positions/PositionsTableMolecule';
import EmptyPlaceholderMolecule from '../../../molecules/placeholders/empty/EmptyPlaceholderMolecule';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import { CV_POSITIONS_EMPTY_MAPPING } from 'src/tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';

type CvPositionsOrganismProps = ComponentProps;

const CvPositionsOrganism: React.FC<CvPositionsOrganismProps> = ({
  className = 'cv-positions-organism',
}) => {
  const { t } = useInternationalization(ORGANISMS_LOCALE_NAMESPACE);
  const { cvActivities } = useCv();

  const positionsExperience = useMemo(
    () =>
      cvActivities &&
      sortPositionsExperience(calculatePositionsExperience(cvActivities)),
    [cvActivities],
  );

  const renderPositions = (loadedPositionsExperience: PositionExperience[]) =>
    loadedPositionsExperience.length > 0 ? (
      <PaperBackgroundMolecule className='cv-positions__background'>
        <PositionsTableMolecule
          className='cv-positions__table'
          positionsExperience={loadedPositionsExperience}
        />
      </PaperBackgroundMolecule>
    ) : (
      <EmptyPlaceholderMolecule
        className='cv-positions__no-positions'
        text={t(CV_POSITIONS_EMPTY_MAPPING)}
        width='100%'
        height='auto'
        minHeight='200px'
      />
    );

  const renderLoading = () => (
    <LoadingBackgroundMolecule
      className='cv-positions__loading'
      height='auto'
    />
  );

  return (
    <Box
      className={`${className} cv-positions`}
      width='100%'
      display='flex'
      flexGrow={1}
      alignItems='stretch'>
      {positionsExperience
        ? renderPositions(positionsExperience)
        : renderLoading()}
    </Box>
  );
};

export default CvPositionsOrganism;
