import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, LinkProps } from '@mui/material';
import buildUrlFromEndpoint from '../../../../tools/navigation/utils/buildUrlFromEndpoint';
import NavEndpoint from '../../../../tools/navigation/endpoints/NavEndpoint';

type LinkAtomProps = LinkProps & {
  to: NavEndpoint;
  autoReplace?: boolean;
  replace?: boolean;
};

const LinkAtom: React.FC<LinkAtomProps> = ({
  className,
  display,
  width,
  height,
  variant,
  color,
  underline,
  children,
  to,
  autoReplace = false,
  replace = false,
  onClick,
}) => (
  <Link
    className={className}
    display={display}
    width={width}
    height={height}
    variant={variant}
    color={color}
    style={{ cursor: 'pointer' }}
    underline={underline}
    component={RouterLink}
    to={buildUrlFromEndpoint(to)}
    replace={
      // eslint-disable-next-line no-restricted-globals
      replace || (autoReplace && buildUrlFromEndpoint(to) === location.pathname)
    }
    onClick={onClick}>
    {children}
  </Link>
);

export default LinkAtom;
