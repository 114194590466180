import React from 'react';

import { Grid, Stack } from '@mui/material';

import useInternationalization from '../../../../../hooks/internationalization/useInternationalization';
import useInternalNavigation from '../../../../../hooks/navigation/useInternalNavigation';
import useExternalNavigation from '../../../../../hooks/navigation/useExternalNavigation';

import { ComponentProps } from '../../../ComponentProps';
import LinkAtom from '../../../atoms/links/LinkAtom';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import {
  FOOTER_COPYRIGHT_MAPPING,
  FOOTER_CREATED_BY_MAPPING,
  FOOTER_CREATED_CREATOR_NAME_MAPPING,
  FOOTER_GITLAB_LINK_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';

type FooterOrganismProps = ComponentProps & {
  height?: string;
};

const FooterOrganism: React.FC<FooterOrganismProps> = ({
  className = 'footer-organism',
  height = 'auto',
}) => {
  const { t } = useInternationalization(ORGANISMS_LOCALE_NAMESPACE);

  const { toActivities } = useInternalNavigation();
  const { toIcvGitLabRepo, toCreatorsWebsite } = useExternalNavigation();

  return (
    <PaperBackgroundMolecule
      className={`${className} footer`}
      component='footer'
      square
      sx={{
        width: '100%',
        height: { height },
        padding: '0% 10%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Grid
        className='footer__content'
        container
        spacing={2}
        alignItems='flex-start'>
        <Grid item container spacing={1} xs={12} sm={4}>
          <Grid item xs={4} sm={12}>
            <LinkAtom
              className='footer__copyright'
              underline='none'
              color='inherit'
              autoReplace
              to={toActivities}>
              {t(FOOTER_COPYRIGHT_MAPPING)}
            </LinkAtom>
          </Grid>
          <Grid item xs={8} sm={12}>
            <Stack
              className='footer__creator'
              direction='row'
              alignItems='center'
              spacing={0.5}
              useFlexGap
              flexWrap='wrap'>
              <TypographyAtom className='footer__created-by'>
                {t(FOOTER_CREATED_BY_MAPPING)}
              </TypographyAtom>
              <LinkAtom
                className='footer__creator-name'
                underline='none'
                color='inherit'
                to={toCreatorsWebsite()}>
                {t(FOOTER_CREATED_CREATOR_NAME_MAPPING)}
              </LinkAtom>
            </Stack>
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={12} sm={8}>
          <Grid item xs={4}>
            <LinkAtom
              className='footer__gitlab-link'
              underline='hover'
              component='button'
              to={toIcvGitLabRepo()}>
              {t(FOOTER_GITLAB_LINK_MAPPING)}
            </LinkAtom>
          </Grid>
          {
            // TODO: Other links
          }
        </Grid>
      </Grid>
    </PaperBackgroundMolecule>
  );
};

export default FooterOrganism;
